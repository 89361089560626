import { graphql, useStaticQuery } from 'gatsby'

export const useImageQueryForPokerPage = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Texas.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner: file(relativePath: { eq: "poker-banner/mobile/banner1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBannerHindi: file(
        relativePath: { eq: "poker-banner/mobile/hindi/banner1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner2: file(
        relativePath: { eq: "poker-banner/mobile/banner2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner2Hindi: file(
        relativePath: { eq: "poker-banner/mobile/hindi/banner2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner3: file(
        relativePath: { eq: "poker-banner/mobile/banner3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner3Hindi: file(
        relativePath: { eq: "poker-banner/mobile/hindi/banner3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      deskBanner: file(
        relativePath: { eq: "poker-banner/desktop/banner1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBannerHindi: file(
        relativePath: { eq: "poker-banner/desktop/hindi/banner1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner2: file(
        relativePath: { eq: "poker-banner/desktop/banner2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner2Hindi: file(
        relativePath: { eq: "poker-banner/desktop/hindi/banner2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner3: file(
        relativePath: { eq: "poker-banner/desktop/banner3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
      deskBanner3Hindi: file(
        relativePath: { eq: "poker-banner/desktop/hindi/banner3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
    }
  `)

  return { data }
}
